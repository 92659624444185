.side-nav {
    background-color: rgb(29, 3, 82);
    height: 100vh;
    width: 30vh;
    /* opacity: 0.1; */
}
ul {
    list-style-type: none;
    width: inherit;
}
.title {
    font-family: var(--FONT-FAMILY);
    font-weight: 600;
    height: 20vh;
    color: wheat;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    padding: 5px;
}

.sections {
    border-top: 1px solid wheat;
}

.sectionItems {
    width: 100%;
    color: wheat;
    font-family: var(--FONT-FAMILY);
    font-weight: 500;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
    padding: 5px;
    height: 10vh;
    /* border: 1px solid red; */
}

.sectionItems  :hover {
    color: white;
    text-decoration: none;
    font-size: 15px;
}
.drawer-icon {
    position: fixed;

}
.drawer-react-fragment {
    overflow-y:none;
}

a {
    color: inherit;
}

:root {
    --FONT-FAMILY: 'Ink Free','Copperplate Gothic Light','Courier New' , monospace;
  }
.container {
    margin: 0;
    padding: 0;
    max-width: inherit;
    /* height: 100vh; */
}

.display-view {
    max-height: 100vh;
    /* background-color: whitesmoke; */
    float: right;
    width: 100%;
}

.display-frame {
    margin: 25vh 0 30vh 0;
    height: 50%;
}

.details {
  margin: auto;
  display: block;
  position: relative;
  font-size: 5vh;
  font-family: var(---FONT-FAMILY);
  padding-left:15%; 
  line-height: 4mm;
  font-weight: bolder;
}

.imagePos {
  margin: auto;
  display: block;
  position: relative;
  font-size: 5vh;
  padding-left:5%; 
  line-height: 4mm;
   max-width: 100%;
  height: auto;
}
.display-frame {
  margin: 50vh 0 30vh 0;
  height: 50%;
}
/* .display-frame .row{
  margin: 25vh 0 30vh 0;
  height: 50%;
} */
.container {
  margin: 0;
  padding: 0;
  max-width: inherit;
  /* max-height: inherit; */
}
.details {
    margin: auto;
    display: block;
    position: relative;
    font-size: 5vh;
    font-family: var(--FONT-FAMILY);
    padding-left:15%; 
    line-height: 5mm;
    font-weight: bolder;
  }
  .display-frame-wxp {
    margin: 5vh 0 5vh 0;
    height: 100%;
  }

  .stepper .MuiTypography-body1 {
    font-family: var(--FONT-FAMILY);
  }
  .stepper .MuiTypography-body2  {
    font-family: var(--FONT-FAMILY);
  }
  .stepper .MuiPaper-root {
    background-color: inherit;
  }

  .stepper .pwc .MuiStepIcon-root.MuiStepIcon-active {
    color: orange;
  }

  .stepper .pwc .MuiStepIcon-root.MuiStepIcon-completed {
    color: orange; 
  }

  .stepper .ltts .MuiStepIcon-root.MuiStepIcon-active {
    color: #003F72;
  }

  .stepper .ltts .MuiStepIcon-root.MuiStepIcon-completed {
    color: #003F72; 
  }

  .stepper .MuiStepLabel-label.MuiStepLabel-active {
      font-weight: 900;
  }

  .stepper .ltts .MuiStepLabel-label.MuiStepLabel-active {
    color: #003F72; 
  }

  .stepper .pwc .MuiStepLabel-label.MuiStepLabel-active {
    color: orange;
  }

  img {
      margin-bottom: 12%;
      display: block;
  }

  /* .logos {
      margin-left: 10%;
  } */
  .imageClass{
    max-width: 100%;
    height: auto;
  }
  .page-title span{
    font-size: -webkit-xxx-large;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: var(--FONT-FAMILY);
    font-weight: bold;
    z-index: 1;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .page-content .span-in-page-content{
    font-size: -webkit-x-large;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: var(--FONT-FAMILY);
    font-weight: bold;
    z-index: 1;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
